// Vue
import { ref } from 'vue';

// Composables
import useDropbox from '~/composables/molecules/useDropbox';

// Components
import Dropbox from '@ice-products-ui/icecorp-vue-library/Dropbox';
import type { THubDefault } from '~/components/O/Hub/OHub.types';

/**
 * @description Компосейбл для регулировки видимых элементов в сайдбаре.
 * @returns {{mapShowMoreState: Ref<UnwrapRef<Map<any[] | undefined, {state: boolean, visibleItems: number}>>>, isVisibleItem: ComputedRef<(idx: number, uniqKey: any[]) => boolean>, toggleVisibleItems: (uniqKey: any[]) => void}}
 */
export function useHubSidebarVisibleItems<CatalogType>(
  categories: THubDefault<CatalogType>['categories'],
  visibleItems: number = 6,
) {
  const mapShowMoreState = ref<Map<any[] | undefined, { state: boolean; visibleItems: number }>>(
    new Map(),
  );

  const isVisibleItem = computed(() => (idx: number, uniqKey: any[]) => {
    const state = mapShowMoreState.value.get(uniqKey);

    if (!state) return false;

    return idx + 1 > state?.visibleItems;
  });

  const isVisibleButton = computed(() => categories.length > visibleItems);

  const toggleVisibleItems = (uniqKey: any[]) => {
    !mapShowMoreState.value.get(uniqKey)?.state
      ? mapShowMoreState.value.set(uniqKey, {
          state: true,
          visibleItems: 9999,
        })
      : mapShowMoreState.value.set(uniqKey, {
          state: false,
          visibleItems,
        });
  };

  mapShowMoreState.value.set(categories, {
    state: false,
    visibleItems,
  });

  return {
    mapShowMoreState,
    isVisibleItem,
    isVisibleButton,
    toggleVisibleItems,
  };
}

/**
 * @description Компосейбл для управления дропбокс элементом.
 * @returns {{dropboxRef: Ref<UnwrapRef<InstanceType<Dropbox> | null>>, onClickOutsideHandler: () => void, disableState: Ref<UnwrapRef<boolean>>, toggleBodyScroll: () => void, dropboxState: Ref<UnwrapRef<boolean>>}}
 */
export function useDropboxHub() {
  const { dropboxState, disableState, toggleBodyScroll } = useDropbox();
  const dropboxRef = ref<InstanceType<typeof Dropbox> | null>(null);
  const onClickOutsideHandler = () => {
    if (!dropboxRef.value) return;
    dropboxRef.value.close();
  };

  return {
    dropboxRef,
    onClickOutsideHandler,
    dropboxState,
    disableState,
    toggleBodyScroll,
  };
}
